(function(){
    'use strict';
    /* global amenitiesCenter, $, angular */

    angular.module("modules.googleMaps")
        .controller("AmenitiesMapCtrl", ['$scope', 'NgMap', '$timeout', 'PlacesService', '$q',
            function($scope, NgMap, $timeout, PlacesService, $q) {

                $scope.center  = new google.maps.LatLng(amenitiesCenter.lat, amenitiesCenter.lon);
                $scope.selectedCategory = null;
                $scope.activeMarker = {};

                var service = null;

                NgMap.getMap().then(function(map) {
                    $scope.prepareMap(map);
                });

                NgMap.defaultOptions = {
                    marker: {
                        animation: google.maps.Animation.DROP,
                        icon: '/images/site/icons/icon-pin-a.png',
                        optimized: true
                    }
                };

                $scope.mapOptions = {
                    styles: [],
                    mapTypeControl: false,
                    streetViewControl: false,
                    clickableIcons: false,
                    fullscreenControl: false,
                    maxZoom: 15
                };
                
                $scope.selectCategory = function(category) {
                  if (category === undefined || ($scope.selectedCategory && category == $scope.selectedCategory)) {
                    return;
                  }

                  $scope.selectedCategory = category;
                };

                $scope.prepareMap = function(map) {
                    $scope.map = map;
                    $scope.map.setOptions($scope.mapOptions);

                    google.maps.event.addListener($scope.map, 'click', function() {
                        $timeout(function() {
                            for(var infoWindow in map.infoWindows) {
                                map.hideInfoWindow(infoWindow);
                            }
                        });
                    });

                    service = PlacesService(map);
                    $scope.categories = service.categories;

                    var places = $scope.categories.map(placesSearchFor);

                    $scope.selectCategory($scope.categories[0]);
                };

                var placesSearchFor = function(category) {
                  return service.search(category, $scope.center)
                    .then(function(results) {
                      addPlacesMarkerToMap(category, results);
                    })
                    .catch(function(status) {
                      // Remove the category from the list if we don't find any results
                      $scope.categories.splice($scope.categories.indexOf(category), 1);
                    });
                };

                var addPlacesMarkerToMap = function(category, searchResult) {
                  angular.forEach(searchResult, function(place) {
                    var category_index = $scope.categories.indexOf(category);

                    var marker = {
                      id: category_index + place.place_id,
                      place_id: place.place_id,
                      name: place.name,
                      latitude: place.geometry.location.lat(),
                      longitude: place.geometry.location.lng(),
                      category: category_index,
                      category_icon: category.name,
                      custom: false,
                      options: {
                        visible: true
                      }
                    };

                    $scope.categories[category_index].markers.push(marker);
                  });

                };

              $scope.showWindow = function(event, marker) {

                var vm = this;
                var markers = $scope.selectedCategory.markers;
                var catMarker = markers[markers.indexOf(marker)];

                service.details(marker.place_id)
                  .then(function(details) {
                    catMarker.address = details.formatted_address.replace(', United States', '');
                    catMarker.phone = details.formatted_phone_number;
                    catMarker.has_photo = false;
                  })
                  .then(function() {
                    $scope.map.panTo({
                      'lat': marker.latitude,
                      'lng': marker.longitude
                    });

                    $scope.activeMarker = catMarker;
                    $scope.map.showInfoWindow('infowindow', vm);
                  });

              };
            }
        ]);
}());