(function(){
  'use strict';
  /* global $, angular */

  angular.module('app.common')
  .directive('autoHeight', ['DeviceService', function (DeviceService) {
    return {
      restrict: 'A',
      link: function (scope, elem, attrs) {

        $(document).ready(autoHeight);

        function autoHeight(){

          if(attrs.isPhone != undefined && DeviceService.check() == 'phone'){//trigger when mobile only
            resize(0);
          }else if(attrs.translateHeight != undefined && DeviceService.check() == 'phone'){//define actual height in px to prevent element from jumping in ios using scrollMagic
            elem.height(elem.height());
          }else{        
            offset = elem.data('offset');
            if($(".homepage").length && DeviceService.check() == 'phone'){
              offset = 0;
            }
            resize(offset);
          }
        }

        function resize(offset){
          elem.height($(window).height() - offset);
        }
      }
    };
  }])
}());