/*jslint white: true, nomen: true */
/*global angular */

(function() {
  'use strict';

  angular.module("app.common")
    .directive("subNav", function() {
      return {
        restrict: 'E',
        transclude: true,
        scope: {
			class: '@',
			login: '@'
		},
        templateUrl: '/directive/sub-nav',
        link: function(){
			console.log('sub-nav');
		}
      };
    });
}());
