(function(){
  'use strict';
  /* global $, angular */

  angular.module('app.common')
  .directive('rmPopup', ['$timeout', function($timeout) {

    return {
      templateUrl: '/rm-popup',
      restrict: 'A',
      scope: {},
      replace: true,
      transclude: true,
      link: function (scope, element, attrs) {

        var popup = angular.element(element[0])
          , parent = angular.element(element[0]).parent();

        parent.on('mouseenter', function () {
          popup.addClass('active');
          $timeout(function () {
            popup.addClass('fade-in');
          }, 150);
        });
        parent.on('mouseleave', function () {
          popup.removeClass('fade-in');
          $timeout(function () {
            popup.removeClass('active');
          }, 150);
        });
        
      }
    };

  }]);
}());