(function() {
  'use strict';

  angular
  
    .module('modules.dataServices')

    .directive("preloadDataCache", ["DataCache",
      function(DataCache) {
        console.log("here");
        return {
          link: function(scope, element, attrs) {
            element.hide();
            DataCache.put(attrs.preloadResource, JSON.parse(element.html().replace(/(<([^>]+)>)/ig,"")) );
          }
        };
      }
    ])
  ;

})();
