/*jslint white: true, nomen: true */
/*global angular */
(function() {
    'use strict';

    angular.module('app.site-map')
        .controller('homepageCtrl', ['$scope', '$timeout', '$window', 'DataService', 'DeviceService',
            function($scope, $timeout, $window, DataService, DeviceService) {

              var phHeight,
                  phSidebar = $('.ph-sidebar'),
                  phSidebarHeight,
                  phLotMap = $('.ph-lot-map'),
                  phLotMapWidth,
                  phLotMapHeight;

              $scope.getLotMapDimension = function() {
                phSidebarHeight = phSidebar.outerHeight(),
                phLotMapWidth = phLotMap.outerWidth(),
                phLotMapHeight = phLotMap.outerHeight();

                if (DeviceService.check() === 'phone') {
                  phHeight = 0;
                } else if (DeviceService.check() === 'iPad' && DeviceService.orientation() === 'portrait') {
                  phHeight = phLotMapHeight;
                } else {
                  phHeight = phSidebarHeight;
                }
              };

            	DataService($window.location.href)
                .promise
                .then(function(result) {
                  $scope.lotMap = result.lotMap;

                  $timeout(function() {
                    $scope.getLotMapDimension();

                    angular.forEach($scope.lotMap.lots, function(lot, key) {

                      var lotElTop = lot.lot_map_top / 100 * phLotMapHeight,
                          lotElLeft = lot.lot_map_left / 100 * phLotMapWidth;

                      // top
                      if (lotElTop < phHeight / 2) {
                        // top left
                        if (lotElLeft < phLotMapWidth / 2) {
                          console.log('top left');
                          lot.flyout_position = 'top left';
                        }
                        // top right
                        else if (phLotMapWidth / 2 <= lotElLeft) {
                          console.log('top right');
                          lot.flyout_position = 'top right';
                        }
                      }
                      // bottom
                      else if (phHeight / 2 <= lotElTop) {
                        // bottom left
                        if (lotElLeft < phLotMapWidth / 2) {
                          console.log('bottom left');
                          lot.flyout_position = 'bottom left';
                        }
                        // bottom right
                        else if (phLotMapWidth / 2 <= lotElLeft) {
                          console.log('bottom right');
                          lot.flyout_position = 'bottom right';
                        }
                      }

                    });
                  }, 1000);
                });

              $(window).on('load resize', function(e) {
                if (DeviceService.check() === 'phone' || (DeviceService.check() === 'iPad' && DeviceService.orientation() === 'portrait')) {
                  $scope.isCollapsed = true;
                } else {
                  $scope.isCollapsed = false;
                }
                $scope.getLotMapDimension();
              });

            }
        ]);
}());
