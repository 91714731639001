(function(){
'use strict';
/* global $, angular */

angular.module('app.common')
  .directive('detectHeight', ['DeviceService',
    function(DeviceService) {
      return {
        restrict: 'A',
        link: function(scope, elem, attr) {

          if (DeviceService.check() != 'phone') {
            $(document).ready(getHeight);
            $(window).resize(getHeight);
          }

          function getHeight() {
            //this is helpful when using flexbox
            elem.css('height', elem.outerHeight());

            //push any elements with align-bottom class to the bottom
            $(elem).find('.align-bottom').css({
              position: 'absolute',
              width: '100%',
              bottom: 0
            });
          }

        }
      };
    }
  ])
}());