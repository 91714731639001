(function() {
  'use strict';
  /* global, angular */
  angular

    .module("modules.forms")

    .service("FormComponentService", [
      'FormComponentsOptionsService',
      function(FormComponentsOptionsService) {
        var service = {};

        service.getDefualtConfig = function() {
          return {
            textPlaceholders: true,
            selectPlaceholders: true,
            buttomPlaceholders: true
          };
        };

        service.getNoPlaceholdersConfig = function() {
          return {
            textPlaceholders: false,
            selectPlaceholders: false,
            buttomPlaceholders: false
          };
        };

        service.getRequired = function(required) {
          if (typeof required === 'undefined') {
            required = true;
          }

          return required;
        };

        service.definePlaceholder = function(attr, show, required) {
          if (show) {
            return service.getRequired(required) ? attr + '*' : attr;
          }

          return '';
        };

        service.getPlaceholder = function(show, value, isRequired) {
          if (show) {
            return (isRequired) ? value + '*' : value;
          }

          return '';
        };

        service.defineAttr = function(attr, defaultValue) {
          if (typeof attr === 'undefined') {
            return defaultValue;
          }

          return attr;
        };

        service.getDatePickerDates = function(type) {
          var minDate = new Date();
          var maxDate = new Date();

          if (type === 'futureOnly') {
            minDate.setDate(minDate.getDate() + 1);
            maxDate.setYear(maxDate.getFullYear() + 3);
            maxDate.setDate(maxDate.getDate() + 1);
          }

          if (type === 'pastOnly') {
           minDate.setYear('1900');
           minDate.setDate(minDate.getDate() + 1);
            maxDate.setDate(maxDate.getDate());
          }

          return {
            minDate: minDate,
            maxDate: maxDate
          };
        };

        service.getOptions = function(type) {
          if (type === 'states') {
            return FormComponentsOptionsService.getStates();
          }

          if (type === 'boolean') {
            return FormComponentsOptionsService.getBooelans();
          }

          if (type === 'referrals') {
            return FormComponentsOptionsService.getReferrals();
          }

          if (type === 'timeframes') {
            return FormComponentsOptionsService.getTimeframes();
          }

          if (type === 'desiredLocations') {
            return FormComponentsOptionsService.getDesiredLocations();
          }

          if (type === 'priceRanges') {
            return FormComponentsOptionsService.getPriceRanges();
          }

          if (type === 'homeTypes') {
            return FormComponentsOptionsService.getHomeTypes();
          }

          if (type === 'salesSpecialists') {
            return FormComponentsOptionsService.getSalesSpecialists();
          }

          return {};
        };

        service.spinalCase = function(str) {
          return str.replace('#', '')
            .replace(/(?!^)([A-Z])/g, ' $1')
            .replace(/[_\s]+(?=[a-zA-Z0-9])/g, '-').toLowerCase();
        };

        service.snakeCase = function(str) {
          return str.replace('#', '')
            .replace(/(?!^)([A-Z])/g, ' $1')
            .replace(/[_\s]+(?=[a-zA-Z0-9])/g, '_').toLowerCase();
        };

        return service;
      }]);
}());