(function() {
  'use strict';

  angular

    .module('modules.forms')

    .service('TestDataService', [
      function() {
        var testDataService = {};

        testDataService.get = function(formName) {
          return this[formName]();
        };

        testDataService.warrantyRequest = function() {
          return {
            first_name: "John",
            last_name: "Doe",
            email: "john@doe.com",
            phone: "1234567890",
            address_1: "123 Main Street",
            city: "Anytown",
            state: "CA",
            zip: "12345"
          };
        };

        testDataService.buyingApplication = function() {
          return {
            first_name: "Tester",
            last_name: "Tester",
            email: "tester@tester.com",
            primary_phone: "5598244493",
            secondary_phone: "5598244493",
            address: "123 Main Street",
            city: "Fresno",
            state: "CA",
            zip: "93720",
            dob: "1973-09-13",
            ssn: "111223333",
            employer: "Acme Cement",
            how_long_with_company: "10 years",
            gross_monthly_income: "5000",
            total_outstanding_debts: "10000",
            total_monthly_payment_towards_debts: "1000",
            do_you_own_land: "Yes",
            how_much_still_owed_on_your_land: "$50,000",
            consent: true,
            type: "buyingApplication",
            origin: "htt: /localhos: 000/buying-your-home/buying-application"
          };
        };

        testDataService.realtorReferral = function() {
          return {
            "customer": {
              "address": "456 Main St",
              "buying_interest": "3 - 6 Months",
              "city": "Anytown",
              "desired_hometype": [
                "Two-story",
                "w/ Living Room",
                "1,800 - 2,200 Sq. Ft."
              ],
              "selected_regions": [
                "2",
                "1",
                "4"
              ],
              "email": "jill@homebuyer.com",
              "first_name": "Jill",
              "last_name": "Homebuyer",
              "origin": "http://localhost:3000/realtor-referral",
              "price_range": "$200,000 - $250,000",
              "primary_phone": "5553331234",
              "state": "CA",
              "type": "realtorReferral",
              "zip": "90210"
            },
            "realtor": {
              "address": "123 Realty Dr",
              "broker_office": "Joe's Realty",
              "city": "anytown",
              "comments": "I am a really awesome realtor!",
              "email": "joe@realtor.com",
              "first_name": "Joe",
              "last_name": "Broker",
              "origin": "http://localhost:3000/realtor-referral",
              "primary_phone": "5551231234",
              "secondary_phone": "5552221234",
              "state": "CA",
              "type": "realtorReferral",
              "zip": "90210"
            }
          };
        };

        return testDataService;
      }]);
}());