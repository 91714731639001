(function(){
    'use strict';
    /* global $, angular */

    angular.module("modules.googleMaps")
        .controller("GoogleMapCtrl", ['$scope', 'NgMap', '$timeout',
            function($scope, NgMap, $timeout) {

                NgMap.getMap().then(function(map) {
                    $scope.prepareMap(map);
                });

                NgMap.defaultOptions = {
                    marker: {
                        animation: google.maps.Animation.DROP,
                        icon: '/images/site/find-your-home/icons/icon-pin.png',
                        optimized: true
                    }
                };

                $scope.mapOptions = {
                    styles: [],
                    mapTypeControl: false,
                    streetViewControl: false,
                    clickableIcons: false,
                    fullscreenControl: false,
                    maxZoom: 20
                };

                $scope.regionColors = {
                    default: {
                        fillColor: '#808080',
                        fillOpacity: 0.2
                    },

                    highlighted: {
                        fillColor: '#185c83',
                        fillOpacity: 0.4
                    }
                }

                $scope.prepareMap = function(map) {
                    $scope.map = map;
                    $scope.map.setOptions($scope.mapOptions);

                    // cluster the markers
                    // new MarkerClusterer(
                    //     $scope.map,
                    //      $scope.map.markers,
                    //     {imagePath: '/images/site/markerclusterer'}
                    // );

                    google.maps.event.addListener($scope.map, 'click', function() {
                        $timeout(function() {
                            for(var infoWindow in map.infoWindows) {
                                map.hideInfoWindow(infoWindow);
                            }
                        });
                    });
                };

                $scope.toggleHighlightSubRegion = function(event, subregion) {
                    var fill = $scope.regionColors.highlighted;

                    if($scope.map.shapes[subregion].fillColor === fill.fillColor) {
                        fill = $scope.regionColors.default;
                    }

                    $scope.map.shapes[subregion].set('fillColor', fill.fillColor);
                    $scope.map.shapes[subregion].set('fillOpacity', fill.fillOpacity);
                };

                $scope.toggleMarkerAnimation = function(event, marker) {
                    var animation = null;

                    if($scope.map.markers[marker].animation === null) {
                        animation = google.maps.Animation.BOUNCE; 
                    }

                    $scope.map.markers[marker].setAnimation(animation);
                };

            }
        ]);
}());