  /* global ga */
(function() {
  'use strict';
  angular.module("modules.analytics")
    .service("GoogleAnalyticsService", ['$http',
      function($http) {
        var service = {};

        service.sendPageView = function(key, config) {
          if (typeof config === 'undefined') {
            console.log('Google Analytics config not defined.');
            return false;
          }

          if (typeof config.pageViewUrl === 'undefined') {
            console.log('Google Analytics pageViewUrl not defined.');
            return false;
          }

          if (typeof key === 'undefined') {
            console.log('Google Analytics key not defined.');
            return false;
          }

          if (typeof ga === 'undefined') {
            console.log('Google Analytics service not defined, app may not be in production mode.');
            return false;
          }

          ga('create', key);
          ga(function(tracker) {
            ga('send', 'pageview', config.pageViewUrl);
          });

          return true;
        };

        return service;
      }]);
})();