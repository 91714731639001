(function() {
  "use strict";

  angular
    .module("modules.analytics", [])
    .config(['AnalyticsServiceProvider', function(AnalyticsServiceProvider) {

      var config = {
        clicky: {
          enabled: false
        },
        googleAnalytics: {
          enabled: false,
          key: 'your-key-here'
        },
        facebookPixelAnalytics: {
          enabled: false,
          key: 'your-key-here',
          event: 'Lead'
        }
      };

      AnalyticsServiceProvider.setConfig(config);

    }])
  ;

})();