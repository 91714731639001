(function(){
	'use strict';
	/* global $, angular */

	angular.module("app.common")
	.controller("ParallaxCtrl", ['$scope','DeviceService', function($scope, DeviceService) {

		window.onscroll = function(e) {
			parallax_scroll();
		};

		//parallax data attr{top,speed,height}
		function parallax_set(){
			if(DeviceService.check() != 'phone'){
				$('.parallax-ms').each(function(e){
					var top = $(this).data('top');
					var height = $(this).data('height');
					if(top == "auto"){
						top = $(this).parent().height()/2.2;
					}
					$(this).css({
						'top': top,
						'height': height
					});
					$(this).parent().css('overflow','hidden');
				});
			}
		}

		function parallax_scroll(){
			if(DeviceService.check() != 'phone'){
				var $parallax = $('.parallax-ms');
				if ($parallax !== null){

					$parallax.each(function(){
						var y = window.pageYOffset;
						var result = $(this).data('speed');
						$(this).css('transform','translate3d(0px,' + -window.pageYOffset/$(this).data('speed') + 'px, 0px)');
					});
				}
			}
		}
	}]);
}());