/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('modules.forms')
    .component('formComponentDatepicker', {
      bindings: {
        config: '<compConfig',
        hideLabel: '<compHideLabel',
        label: '@compLabel',
        placeholder: '@compPlaceholder',
        required: '<compRequired',
        datesMode: '@compDatesMode',
        opened: "<compOpened",
        mode: "@compMode",
        onUpdate: '&'
      },
      template: '' +
          '<label ng-if="! $ctrl.hideLabel" for="<% $ctrl.id %>" ng-class="{ required: $ctrl.required, error: $ctrl.error }">' +
              '<% $ctrl.label %>' +
              '<span ng-if="$ctrl.required" class="required">* required</span>' +
          '</label>' +
          '<input type="text"' +
              'ng-attr-id="<% $ctrl.id %>"' +
              'ng-attr-name="<% $ctrl.name %>"' +
              'class="form-control"' +
              'ng-class="{required: $ctrl.required}"' +
              'ng-attr-placeholder="<% $ctrl.placeholder %>"' +
              'uib-datepicker-popup="MM/dd/yyyy"' +
              'datepicker-options="$ctrl.dateOptions"' +
              'show-button-bar="false"' +
              'ng-model="$ctrl.dt"' +
              'is-open="$ctrl.opened"' +
              'ng-click="$ctrl.open($event)"' +
              'ng-change="$ctrl.onUpdate({value: $ctrl.dt})"' +
              'ng-required="$ctrl.required"' +
          '/>',
      controller: ['FormComponentService', function(FormComponentService) {

        this.error = false;

        this.$onInit = function() {
          this.dateOptions = {
            showWeeks: false
          };

          this.defineMode();
          this.setDates();
        };

        this.$onChanges = function(changes) {
          if (changes.config && changes.config.isFirstChange()) {
            return;
          }

          if (! this.init) {
            this.initComponent();
          }
        };

        this.$doCheck = function() {
          if (this.config && this.config.formElement.$submitted) {
            this.error = ! isValid(this.config.formElement[this.name].$error);
          }
        };

        this.initComponent = function() {
          this.id          = FormComponentService.spinalCase(this.config.prefix + '_' + this.label);
          this.name        = FormComponentService.snakeCase(this.label);
          this.type        = FormComponentService.defineAttr(this.type, 'text');
          this.required    = FormComponentService.defineAttr(this.required, true);
          this.hideLabel   = FormComponentService.defineAttr(this.hideLabel, false);
          this.datesMode   = FormComponentService.defineAttr(self.datesMode, 'all');
          this.init        = true;

          this.setPlaceholder();
        };

        this.setPlaceholder = function() {
          if (typeof this.placeholder === 'undefined') {

            this.placeholder = FormComponentService.getPlaceholder(
              this.config.textPlaceholders,
              this.label,
              this.required
            );

          }
        };

        this.defineMode = function() {
          if (typeof this.mode !== 'undefined') {
            this.dateOptions.datepickerMode = this.mode;
          }
        };

        this.setDates = function() {
          var dates = FormComponentService.getDatePickerDates(this.datesMode);

          this.dateOptions.minDate = dates.minDate;
          this.dateOptions.maxDate = dates.maxDate;
        };

        this.open = function(event) {
          event.preventDefault();
          event.stopPropagation();
          this.opened = true;
        };

        var isValid = function(obj) {
          if (obj.hasOwnProperty('required')) {
            return false;
          }

          return true;
        };

      }]
    });

})();