(function(){
    'use strict';
    /* global $, angular */

    angular.module("app.common").directive('slideshow', function() {
        return function(scope, element, attrs) {
            $(window).ready(resizeSlideshow);
            $(window).resize(resizeSlideshow);

            function resizeSlideshow(){
                console.log("resize slideshow");
                element.height($(window).height() - $('.navbar').outerHeight() + 120);
            }
        };
    })
    .directive('resizeSlideshowTwoCol', function() {
        return function(scope, element, attrs) {
            $(window).ready(resizeSlideshowTwoCol);
            $(window).resize(resizeSlideshowTwoCol);

            function resizeSlideshowTwoCol(){
                element.height($(window).height() - $('.navbar').outerHeight());
            }
        };
    });
}());