  /* global ga */
(function() {
  'use strict';
  angular.module("modules.analytics")
    .service("FacebookPixelAnalyticsService", ['$http',
      function($http) {
        var service = {};

        service.sendPageView = function(key, event, config) {
          if (typeof config === 'undefined') {
            console.log('Facebook Pixel Analytics config not defined.');
            return false;
          }

          if (typeof config.name === 'undefined') {
            console.log('Facebook Pixel Analytics name not defined.');
            return false;
          }

          if (typeof key === 'undefined') {
            console.log('Facebook Pixel Analytics key not defined.');
            return false;
          }

          if (typeof fbq === 'undefined') {
            console.log('Facebook Pixel Analytics service not defined, app may not be in production mode.');
            return false;
          }
          
          // initialization
          fbq('init', key); 
          fbq('track', 'PageView'); 
          
          fbq('track', event, {
            value: 0.00,
            currency: 'USD',
            content_name: config.name
          });

          return true;
        };

        return service;
      }]);
})();