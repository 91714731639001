/*jslint white: true, nomen: true */
/*global angular */
(function() {
    'use strict';

    angular.module('app.error-404')
        .controller('Error404Ctrl', ['$scope', '$timeout',
            function($scope, $timeout) {

            	console.log('Error404Ctrl');
                
            }
        ]);
}());
