(function(){
    'use strict';
    /* global $, angular */

    angular
        .module('app.common')
        .directive('scrollMagic', ['$timeout', 'DeviceService',
            function($timeout, DeviceService) {
                return {

                    link: function(scope, elem, attrs) {



                        $timeout(function() {
                            var controller = new ScrollMagic.Controller();

                            var triggerElem = attrs.id;

                            //GLobal SM
                            var scene = new ScrollMagic.Scene({
                                    triggerElement: "#" + triggerElem,
                                    offset: attrs.offset,
                                    triggerHook: 'onEnter'
                                })
                                //.addIndicators()
                                .addTo(controller)
                                .on("enter", function(e) {

                                    // You can't get the offset of a hidden element, so we are going
                                    // to ignore 0,0 elements. Ignoring them means elements that start
                                    // out as hidden but are later shown (using ng-show for example)
                                    // can still be activated using this directive.
                                    // However! That also means you can't use this directive to animate
                                    // an element that starts out at the top left of the page! -wo
                                    var offset = elem.offset();
                                    if (offset.top === 0 && offset.left === 0) {
                                        return;
                                    }

                                    if (elem.hasClass('in')) {
                                        return;
                                    }

                                    $timeout(function() {
                                        if (attrs.stagger !== undefined) {
                                            elem.find(attrs.stagger).velocity("transition." + attrs.transition, {
                                                stagger: attrs.speed
                                            });
                                        }
                                    }, attrs.delay)
                                        .then(function() {
                                            //console.log("Adding the in class", attrs.id);
                                            elem.addClass('in');
                                        });

                                })
                                .on("leave", function(e) {
                                    if (attrs.toggle !== undefined) {
                                        elem.removeClass('in');
                                    }
                                })

                        });

                    }
                };
            }
        ]);
}());