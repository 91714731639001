(function() {
    "use strict";
    /* global $, angular */

    angular.module("app.common").controller("CarouselCtrl", [
        "$scope",
        function($scope) {
            $(".carousel:not(.carousel-flip)").carousel(
                {
                    //interval: 7000
                }
            );

            $(".carousel:not(.carousel-flip").on("slid.bs.carousel", function(e) {
                $(".caption").removeClass("slide-up");
                $(".item.active .caption").addClass("slide-up");
            });

            $(".carousel").velocity(
                { opacity: 1 },
                {
                    duration: 600,
                    delay: 100,
                    complete: function() {
                        $(this)
                            .find(".item")
                            .addClass("loaded")
                            .each(function() {
                                var attr = $(this)
                                    .find(".fill")
                                    .attr("data-style");
                                if (typeof attr !== typeof undefined && attr !== false) {
                                    $(this).attr("style", attr);
                                }
                            });
                    }
                }
            );
        }
    ]);
})();
