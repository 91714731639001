(function() {
  'use strict';

  angular

    .module('modules.forms')

    .service('FormComponentsDeviceService', [
      function() {

      var service = {};

      service.check = function() {
        var type = "desktop";
        /*detect iPad only*/
        if ($(window).width() > 767 && $(window).width() < 1025) {
          type = "iPad";
        }
        /*detect phone only*/
        if ($(window).width() < 768) {
          type = "phone";
        }
        return type;
      };

      service.orientation = function() {
        var orientation = "landscape";
        if ($(window).width() < $(window).height()) {
          return "portrait";
        }
        return orientation;
      };

      service.touch = function() {
        var is_touch = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        return is_touch;
      };

      return service;
    }]);
})();