(function() {
  "use strict";

  angular

    .module('modules.dataServices')

    .service("DataService", ['$http', '$q', 'DataCache',
      function($http, $q, DataCache) {

        return function(resourcePath, resourceName) {

          var data = {
            records: [],
            promise: null
          };

          data.promise = $http.get(resourcePath, {
            cache: DataCache,
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json"
            }
          })
            .then(function(result) {
              if (resourceName) {
                return result.data[resourceName];
              }

              return result.data;
            });


          // This lets you use the service in a simple Angular-y way, just
          // wait for the promise to resolve internally, and let $digest
          // discover the data.
          data.promise
            .then(function(results) {
              angular.copy(results, data.records);
            });

          return data;

        };

      }
    ]);

})();