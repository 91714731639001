(function(){
	
	'use strict';

	angular.module('app.common')
	.directive('navCurrentItem', function(){
		return{
			restrict: 'AE',
			link: function(scope, elem, attrs){
				
				var currentPage = $('main').attr('id');
				console.log(currentPage);
				elem.find('li').each(function( index ) {
					
					if($(this).hasClass(currentPage)){
						console.log(this);
						elem.find('li').removeClass('current');
						$(this).addClass('current');
					}  	
				});
			}
		}
	})

}());