(function() {
    "use strict";
    /* global $, angular */

    angular
        .module("app.common")
        .controller("GlobalCtrl", [
            "$scope",
            "$uibModal",
            function($scope, $uibModal, $log) {
                $scope.getUpdates = function() {
                    var modalInstance = $uibModal.open({
                        templateUrl: "modal-get-updates.html",
                        controller: "getUpdatesModalInstanceCtrl",
                        animation: true,
                        windowClass: "modal-get-updates",
                        size: "lg",
                        resolve: {
                            locations: function() {
                                return $scope.locations;
                            }
                        }
                    });
                };

                $scope.mobileNav = false;

                $scope.locations = [
                    {
                        name: "Coronado Ranch",
                        url: "/planned-community",
                        top: 30,
                        left: 20,
                        width: 100,
                        height: 245,
                        communities: [
                            {
                                top: 30,
                                left: 20,
                                name: "Jones Crossing",
                                url: "/microsite",
                                price: "From the $260's",
                                bedrooms: "3 - 4",
                                bathrooms: "2 - 3",
                                sq_feet: "3,103 - 4,000",
                                starting_price: "$353,500",
                                image_full: "images/site/microsite/bg-slide1.jpg",
                                image_thumb: "images/site/find-your-home/coronado-ranch-thumb.jpg"
                            },
                            {
                                top: 45,
                                left: 26,
                                name: "Reserve",
                                url: "/microsite",
                                price: "From the $300's",
                                bedrooms: "3 - 4",
                                bathrooms: "2 - 3",
                                sq_feet: "3,103 - 4,000",
                                starting_price: "$353,500",
                                image_full: "images/site/microsite/bg-slide1.jpg",
                                image_thumb: "images/site/find-your-home/coronado-ranch-thumb.jpg"
                            },
                            {
                                top: 35,
                                left: 24,
                                name: "SouthBrook",
                                url: "/microsite",
                                price: "From the $270's",
                                bedrooms: "3 - 4",
                                bathrooms: "2 - 3",
                                sq_feet: "3,103 - 4,000",
                                starting_price: "$353,500",
                                image_full: "images/site/microsite/bg-slide1.jpg",
                                image_thumb: "images/site/find-your-home/coronado-ranch-thumb.jpg"
                            },
                            {
                                top: 40,
                                left: 20,
                                name: "Woodbridge",
                                url: "/microsite",
                                price: "From the $360's",
                                bedrooms: "3 - 4",
                                bathrooms: "2 - 3",
                                sq_feet: "3,103 - 4,000",
                                starting_price: "$353,500",
                                image_full: "images/site/microsite/bg-slide1.jpg",
                                image_thumb: "images/site/find-your-home/coronado-ranch-thumb.jpg"
                            }
                        ]
                    },
                    {
                        name: "Highlands Ranch",
                        url: "/planned-community",
                        top: 25,
                        left: 70,
                        width: 100,
                        height: 245,
                        communities: [
                            {
                                top: 25,
                                left: 70,
                                name: "Brentwood",
                                url: "/microsite",
                                price: "From the $290's",
                                bedrooms: "3 - 4",
                                bathrooms: "2 - 3",
                                sq_feet: "3,103 - 4,000",
                                starting_price: "$353,500",
                                image_full: "images/site/microsite/bg-slide1.jpg",
                                image_thumb: "images/site/find-your-home/coronado-ranch-thumb.jpg"
                            },
                            {
                                top: 27,
                                left: 75,
                                name: "Bridgewater",
                                url: "/microsite",
                                price: "From the $260's",
                                bedrooms: "3 - 4",
                                bathrooms: "2 - 3",
                                sq_feet: "3,103 - 4,000",
                                starting_price: "$353,500",
                                image_full: "images/site/microsite/bg-slide1.jpg",
                                image_thumb: "images/site/find-your-home/coronado-ranch-thumb.jpg"
                            },
                            {
                                top: 40,
                                left: 70,
                                name: "Fox Hill Estates",
                                url: "/microsite",
                                price: "(Coming Soon)",
                                bedrooms: "3 - 4",
                                bathrooms: "2 - 3",
                                sq_feet: "3,103 - 4,000",
                                starting_price: "$353,500",
                                image_full: "images/site/microsite/bg-slide1.jpg",
                                image_thumb: "images/site/find-your-home/coronado-ranch-thumb.jpg"
                            },
                            {
                                top: 35,
                                left: 76,
                                name: "Highlands Collection",
                                url: "/microsite",
                                price: "From the $270's",
                                bedrooms: "3 - 4",
                                bathrooms: "2 - 3",
                                sq_feet: "3,103 - 4,000",
                                starting_price: "$353,500",
                                image_full: "images/site/microsite/bg-slide1.jpg",
                                image_thumb: "images/site/find-your-home/coronado-ranch-thumb.jpg"
                            }
                        ]
                    },
                    {
                        name: "Silverado Ranch",
                        url: "/planned-community",
                        top: 55,
                        left: 60,
                        width: 100,
                        height: 100,
                        communities: [
                            {
                                top: 55,
                                left: 60,
                                name: "Silverado Summit",
                                url: "/microsite",
                                price: "From the $270's",
                                bedrooms: "3 - 4",
                                bathrooms: "2 - 3",
                                sq_feet: "3,103 - 4,000",
                                starting_price: "$353,500",
                                image_full: "images/site/microsite/bg-slide1.jpg",
                                image_thumb: "images/site/find-your-home/coronado-ranch-thumb.jpg"
                            }
                        ]
                    },
                    {
                        name: "Southern Highlands",
                        url: "/planned-community",
                        top: 60,
                        left: 40,
                        width: 100,
                        height: 100,
                        communities: [
                            {
                                top: 60,
                                left: 40,
                                name: "Inverness",
                                url: "/microsite",
                                price: "From the $270's",
                                bedrooms: "3 - 4",
                                bathrooms: "2 - 3",
                                sq_feet: "3,103 - 4,000",
                                starting_price: "$353,500",
                                image_full: "images/site/microsite/bg-slide1.jpg",
                                image_thumb: "images/site/find-your-home/coronado-ranch-thumb.jpg"
                            }
                        ]
                    }
                ];
            }
        ])
        .controller("getUpdatesModalInstanceCtrl", function($scope, $uibModalInstance, locations) {
            $scope.locations = locations;
            $scope.cancel = function() {
                $uibModalInstance.close();
            };
        })
        .controller("modalLoginRegisterCtrl", [
            "$scope",
            "$uibModalInstance",
            function($scope, $uibModalInstance) {
                $scope.cancel = function() {
                    $uibModalInstance.close();
                };
            }
        ]);
})();
