(function() {
  'use strict';

  angular.module('app', [
    'angular-inview',
    'duParallax',
    'ui.bootstrap',
    'ngTinyScrollbar',
    'ngTouch',
    'ngSanitize',
    'wu.masonry',
    'slickCarousel',
    'app.common',
    'app.forms',
    'app.homepage',
    'app.site-map',
    'app.error-404',
    'modules.dataServices',
  ])
  .config(['$interpolateProvider', function($interpolateProvider) {
    $interpolateProvider.startSymbol('<%');
    $interpolateProvider.endSymbol('%>');
  }]);

  angular.module('app.common', ['ui.bootstrap']);
  angular.module('app.forms',[]);
  angular.module('app.homepage',[]);
  angular.module('app.site-map',[]);
  angular.module('app.error-404',[]);
  //end
}());
