(function(){
    'use strict';
    /* global $, angular */

    angular.module("modules.googleMaps")
        .service("PlacesService", ['$q',
            function($q) {

                return function(map) {

                    var categories = [
                        {
                            name: 'Schools',
                            icon: '',
                            markers: [],
                            types: ['school', 'ibrary']
                        },
                        {
                            name: 'Restaurants',
                            icon: '',
                            markers: [],
                            types: ['bakery', 'bar', 'cafe', 'food', 'meal_delivery', 'meal_takeaway', 'restaurant']
                        },
                        {
                            name: 'Entertainment',
                            icon: '',
                            markers: [],
                            types: ['amusement_park', 'bowling_alley', 'casino', 'movie_rental', 'movie_theater', 'night_club', 'spa', 'stadium']
                        },
                        {
                            name: 'Recreation',
                            icon: '',
                            markers: [],
                            types: ['aquarium', 'art_gallery', 'campground', 'museum', 'park', 'zoo']
                        },
                        {
                            name: 'Grocery',
                            icon: '',
                            markers: [],
                            types: ['grocery_or_supermarket']
                        },
                        {
                            name: 'Retail Shops',
                            icon: '',
                            markers: [],
                            types: ['beauty_salon', 'bicycle_store', 'book_store', 'clothing_store', 'department_store', 'electronics_store', 'furniture_store', 'hair_care', 'hardware_store', 'home_goods_store', 'jewelry_store', 'pet_store', 'shoe_store', 'shopping_mall']
                        },
                        {
                            name: 'Hospitals',
                            icon: '',
                            markers: [],
                            types:  ['hospital']
                        }
                    ];

                    var placesService = new google.maps.places.PlacesService(map);

                    var placesServiceFactory = function(request, service) {
                        if(service === undefined) {
                            service = 'search';
                        }

                        return $q(function(resolve, reject) {
                            placesService[service](request, function(results, status) {
                              if (status === 'OK') {
                                resolve(results);
                              } else {
                                reject(status);
                              }
                            });
                      });
                    };

                    var details = function(place_id) {
                      var request = {
                        placeId: place_id
                      };

                      return placesServiceFactory(request, 'getDetails');
                    };

                    var makeRequestObject = function(category, center) {
                        return {
                            location: center,
                            radius: 8000,
                            types: category.types
                        };
                    };

                    var search = function(category, center) {
                        return placesServiceFactory(
                            makeRequestObject(category, center)
                        );
                    };

                    return {
                        categories: categories,
                        details: details,
                        search: search
                    };

                }
            }
        ]);
}());