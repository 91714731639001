(function() {
  'use strict';
  /* global $, angular, LassoCRM */

  angular

    .module("modules.forms")

    .service("FormService", [
      '$http',
      'AnalyticsService',
      'FormComponentService',
      'TestDataService',
      'Upload',
      function(
        $http,
        AnalyticsService,
        FormComponentService,
        TestDataService,
        Upload
      ) {
        var formService = {};

        formService.getComponentConfig = function(type, formElement) {
          if (type === 'default') {
            return formService.updateConfig(FormComponentService.getDefualtConfig(), formElement);
          }

          if (type === 'noPlaceholders') {
            return formService.updateConfig(FormComponentService.getNoPlaceholdersConfig(), formElement);
          }

          console.log('No form component config type set.');

          return false;
        };

        formService.updateConfig = function(config, formElement) {
          config.formElement = formElement;
          config.prefix = formElement.$name;
          return config;
        };

        formService.toggleSelection = function(id, selectedItems) {
          if (!(selectedItems instanceof Array)) {
            selectedItems = [];
          }

          if (!id) {
            return selectedItems;
          }

          var idx = selectedItems.indexOf(id);

          if (idx > -1) {
            selectedItems.splice(idx, 1);
          } else {
            selectedItems.push(id);
          }

          return selectedItems;
        };

        formService.removeSelection = function(id, selectedItems) {
          if (!(selectedItems instanceof Array)) {
            selectedItems = [];
          }

          if (!id) {
            return selectedItems;
          }

          var idx = selectedItems.indexOf(id);
          // is already  selected, remove it
          if (idx > -1) {
            selectedItems.splice(idx, 1);
          }

          return selectedItems;
        };

        formService.requirePreviousDate = function(formDateElement) {
          var cutoff = new Date();
          cutoff.setYear(cutoff.getFullYear() - 3);

          var current = new Date(formDateElement);
          current.setDate(current.getDate() + 1);

          return current >= cutoff ? true : false;
        };

        formService.pageResizeOnSubmit = function() {
          var wh = $(window).height();
          var h = $('.header').height() + $('footer').height();

          var $p = $('.full-interest-list');
          $p.height(wh - h);
        };

        formService.prefillForm = function(prefillMode, formName) {
          var prefillContent = {
            formData: {
              origin: window.location.href,
              type: formName
            },
            formResult: {
              showForm: true,
              showLoader: false,
              showMessage: false
            }
          };

          if (typeof LassoCRM !== 'undefined' && LassoCRM) {
            prefillContent.formData.guid = LassoCRM.tracker.readCookie("ut");
          }

          switch (formName) {
            // If needed, pre-fill specific form data here in a case by case bsis
            case 'fullInterestListForm':
              prefillContent.formData.selected_communities = [];
              break;
            case 'warrantyRequestForm':
              prefillContent.formData.owner_type = 'homeowner';
              prefillContent.formData.service_issues = [];
              break;
            default:
              break;
          }

          if (prefillMode) {
            prefillContent.formData = formService.mix(
              TestDataService.get(formName),
              prefillContent.formData
            );
          }

          return prefillContent;
        };

        formService.mix = function(source, target) {
          for (var key in source) {
            if (source.hasOwnProperty(key)) {
              target[key] = source[key];
            }
          }
          return target;
        };

        formService.submitAndHandleAnalytics = function(url, formData, formAnalytics) {
          return formService
            .submit(url, formData)
            .then(function(result) {
              if (typeof formAnalytics !== 'undefined' && result.status === 'success') {
                AnalyticsService.logTrackingClick(formAnalytics);
              }

              return result;
            });
        };

        formService.submit = function(url, formData) {
          if (formData.hasUpload) {
            return Upload.upload({
                url: url,
                method: 'POST',
                data: formData
              })
              .then(
                formService.formServiceSubmitResultWash,
                formService.formServiceSubmitResultWash
              )
              .catch(function(data, status, headers, config) {
                console.error("API had an error");
                console.error(data, status, headers, config);
              });
          }

          return $http({
            method: 'POST',
            url: url,
            data: $.param(formData),
            paramSerializer: '$httpParamSerializerJQLike',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            } // set the headers so angular passing info as form data (not request payload)
          })
            .then(
              formService.formServiceSubmitResultWash,
              formService.formServiceSubmitResultWash
            );
        };

        formService.getSubmitResultFailMessageText = function() {
          return 'Something went wrong, try again later.';
        };

        formService.getValidationError = function(data) {
          var key = Object.keys(data)[0];

          return data[key][0];
        };

        formService.formServiceSubmitResultWash = function(result) {
          var message = formService.getSubmitResultFailMessageText();

          var failResult = {
            status: 'failure',
            showForm: true,
            showLoader: false,
            showMessage: true,
            message: message
          };

          if (result === undefined || result.data === undefined) {
            return failResult;
          }

          if (result.data.status === undefined || result.data.status === 'failure') {

            // handle 422 validation errors
            if(result.status == 422) {
              failResult.message = formService.getValidationError(result.data);
            }

            // handle unauthorized response
            if(result.status == 449) {
              failResult.message = result.status;
            }

            return failResult;
          }

          var successResult = {
            status: 'success',
            showForm: false,
            showLoader: false,
            showMessage: true,
            data: result.data.resultData,
            message: result.data.message
          };

          return successResult;
        };

        formService.broadcastSubmitAttempt = function(scope, prefix, form) {
          console.log('broadcasting submit: ', prefix + '-submit');
          console.log('form: ', form);
          scope.$broadcast(prefix + '-submit', form);
        };

        return formService;
      }]);
}());