(function(){
    'use strict';
    /* global $, angular */

    angular.module("app.common").directive('carouselFlip', function() {
        return function(scope, elem, attrs) {
            elem.on('slide.bs.carousel', function () {
                elem.find('.item:not(.active)').css('z-index',0);
                elem.find('.item.active').css('z-index',1);
            })
            elem.on('slid.bs.carousel', function () {
                elem.find('.item.active').css('z-index',2);
                if(elem.data('direction') == 'next'){
                    elem.find('.item.active').css({'top':'-100%'}).velocity({top:'0%'},600,'easeOutQuad');
                }else{
                    elem.find('.item.active').css({'top':'100%'}).velocity({top:'0%'},600,'easeOutQuad');
                }
            })
        };
    });
}());