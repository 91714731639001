(function(){
	'use strict';
	/* global $, angular */

	angular.module("app.common").controller("MenuCtrl", function($scope) {

	    $scope.hoverOver = function($event) {
			$(".carousel").carousel("pause");
	    };
	    $scope.hoverOff = function(event) {
			$(".carousel").carousel("cycle");
	    };

	});
}());