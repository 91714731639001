(function() {
  "use strict";

  angular
    .module('modules.dataServices')
    .service('IntervalService', IntervalService);

    function IntervalService($http) {
      return {
        getIntervalFor: getIntervalFor
      };

      function getIntervalFor(list, column, step) {
          var range = getRange(list, column);
          return interval(range, step);
      }

      function interval(range, step) {
        var res = [];
        var min = range.min;
        var max = range.max;
        
        var startRange = min - (min % step); // start with one notch below the min
        var endRange = (max % step === 0) ? max : (max + step) - (max % step); // end with one notch above the max or with the max

        for (var i = startRange; i <= endRange - step; i = i + step) {
          res.push({min: i, max: i + step});
        }

        return res;
      }

      function getRange(list, column) {
        var ranges = {
            min: 0,
            max: 0
        };

        if(!list) {
            return ranges;
        }

        if (typeof list === 'object') {
          var l2 = [];
          l2 = Object.keys(list).map(function(k) { return list[k]; });
          list = l2;
        }

        var objCol = function(c) {
          return c[column];
        };

        var nonZero = function(value) {
          // we don't care about non-numerics here
          if (!parseFloat(value)) {
            return value;
          }

          return (parseFloat(value) > 0) ? value : false;
        };

        ranges.min = Math.min.apply(Math, list.map(objCol).filter(nonZero));
        ranges.max = Math.max.apply(Math, list.map(objCol).filter(nonZero));

        return ranges;
      }
    }

})();