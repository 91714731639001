  /* global clicky_custom, clicky */
(function() {
  'use strict';
  angular.module("modules.analytics")
    .service("ClickyService", [
      function() {
        var service = {};

        service.sendPageView = function(config, result) {
          if (typeof config === 'undefined') {
            console.log('Clicky config not defined.');
            return false;
          }

          if (typeof clicky === 'undefined') {
            console.log('clicky not defnied.');
            return false;
          }

          clicky_custom.visitor = {
            lead_id: result.data.leadID,
            username: result.data.email,
            signup: '1'
          };

          clicky_custom.goal = {
            name: config.name
          };

          clicky.goal(config.goal);
          clicky.log(config.pageViewUrl);

          return true;
        };

        return service;
      }]);
})();