/*jslint white: true, nomen: true */
/*global angular, communityId */
(function() {
  'use strict';

  angular
    .module('app.forms')
    .component('formModalGetUpdates', {
      bindings: {
        dismissFunction: '&'
      },
      templateUrl: '/common/templates/form-modal-get-updates',
      controller: ['$timeout', 'FormService', 'DataService',
        function($timeout, FormService, DataService) {

          var ctrl = this;

          this.$postLink = function() {
            var prefillMode = false;
            var formName    = 'modalGetUpdatesForm';
            var prefillData = FormService.prefillForm(prefillMode, formName);

            this.formData   = angular.copy(prefillData.formData);
            this.formResult = angular.copy(prefillData.formResult);
            this.compConfig = FormService.getComponentConfig('noPlaceholders', this[formName]);
            this.neighborhoodOptions  = DataService('/api/community', 'communities').records;

            this.formAnalytics = {
              googleAnalytics: {
                pageViewUrl: '/ajax-goals/' + formName
              }
            };
          };

          this.updateFormData = function(key, value) {
            this.formData[key] = value;
          };

          this.validateForm = function(form) {
            if (! form.$valid) {
              return;
            }

            this.formResult.showForm = false;
            this.formResult.showLoader = true;

            this.prepareFormData();

            FormService
              .submitAndHandleAnalytics('/forms/modal-get-updates', this.formData, this.formAnalytics)
              .then(this.handleSubmitResults)
              .then(this.dismissModal);
          };

          this.prepareFormData = function() {
            this.formData.selected_communities = [this.formData.selected_communities];
          };

          this.handleSubmitResults = function(result) {
            ctrl.formResult = result;

            return result;
          };

          this.dismissModal = function(result) {
            if (result.status === 'success') {
              $timeout(ctrl.dismissFunction, 4000);
            }

            return result;
          };

        }]
    });

})();
