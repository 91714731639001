/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('modules.forms')
    .component('formComponentValidator', {
      bindings: {
        config: '<compConfig',
        name: '<compName'
      },
      template: '' +
        '<span ng-messages="$ctrl.error">' +
          '<div ng-messages-include="/forms/templates/validation-messages"></div>' +
        '</span>',
      controller: function() {

        this.$onInit = function() {
          this.error = false;
        };

        this.$doCheck = function() {
          if (this.config && this.config.formElement.$submitted) {
            this.error = this.config.formElement[this.name].$error;
          }
        };

      }

    });

})();
