(function(){
  'use strict';
  /* global $, angular */

  angular.module('app.common')
  .directive('autoMarginTop', [function () {
    return {
      restrict: 'A',
      link: function (scope, elem, attr) {

        $(document).ready(autoMarginTop);
        $(window).resize(autoMarginTop);

        function autoMarginTop(){
          elem.css('margin-top',$(window).height() - elem.data('offset'));
        }
        
      }
    };
  }])
}());