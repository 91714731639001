(function(){
    'use strict';
    /* global $, angular */

    angular
        .module('app.common')
        .directive('dropdownButton', ['$timeout', function ($timeout) {   
        return {
            restrict: 'A',
            link: function (scope, elem, attrs) {
                $timeout(function(){

                    elem.find("li a").bind("click",function(e){
                        e.preventDefault();
                        elem.find("span.text").text($(this).text());
                    })

                    if(elem.hasClass("has-checkbox")) {
                        $('.has-checkbox .dropdown-menu').on({
                            "click":function(e){
                              e.stopPropagation();
                            }
                        });
                    }
                });
            }
        };     
    }])
    }());