/* global angular */
(function() {
  'use strict';
  angular
    .module("modules.analytics")
    .provider('AnalyticsService', [function() {
      var config = {
        clicky: {
          enabled: false
        },
        googleAnalytics: {
          enabled: false
        },
        facebookPixelAnalytics: {
          enabled: false
        }
      };

      this.setConfig = function(value) {
        config = value;
      };

      this.$get = [
        "GoogleAnalyticsService",
        "FacebookPixelAnalyticsService",
        "ClickyService",
        function AnalyticsService(GoogleAnalyticsService, FacebookPixelAnalyticsService, ClickyService) {
          var service = {};

          // Example config to be passed from parent page's controller to form directives
          service.getDefaultAnalyticsConfig = function() {
            return {
              clicky: {
                name: 'Example Form',
                goal: '123',
                pageViewUrl: '/ajax-goals/example'
              },
              googleAnalytics: {
                pageViewUrl: '/ajax-goals/example'
              },
              facebookPixelAnalytics: {
                name: 'Example Form'
              }
            };
          };

          service.logTrackingClick = function(formAnalytics) {
            if (config.clicky.enabled) {
              ClickyService.sendPageView(formAnalytics.clicky);
            }

            if (config.googleAnalytics.enabled) {
              GoogleAnalyticsService.sendPageView(
                config.googleAnalytics.key,
                formAnalytics.googleAnalytics
              );
            }

            if (config.facebookPixelAnalytics.enabled) {
              FacebookPixelAnalyticsService.sendPageView(
                config.facebookPixelAnalytics.key,
                config.facebookPixelAnalytics.event,
                formAnalytics.facebookPixelAnalytics
              );
            }

            return true;
          };

          return service;
        }];
    }]);

})();