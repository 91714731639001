(function(){
  'use strict';
  /* global $, angular */

  angular.module('app.common')
  .service('scrollMagic', ['$rootScope', function ($rootScope) {
        var controller = new ScrollMagic.Controller();
        function toggleClass(e,target,className){
            if (e === "enter") {
              $(target).addClass(className);
            }
            if (e === "leave") {
              $(target).removeClass(className);
            }
        }
        this.affix = function(elem, target, className) {
          new ScrollMagic.Scene({triggerElement: elem})
            .triggerHook("onLeave")
            .on('enter leave', function(e) {
              toggleClass(e.type, target, className);
            })
            //.addIndicators()
            .addTo(controller);
        };
      }
  ]);

}());