/*jslint white: true, nomen: true */
/*global angular */
(function() {
    'use strict';

    angular.module('app.site-map')
        .controller('SiteMapCtrl', ['$scope', '$timeout',
            function($scope, $timeout) {

            	console.log('SiteMapCtrl');
                
            }
        ]);
}());
